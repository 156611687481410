/* You can add global styles to this file, and also import other style files */
@import "assets/css/bootstrap.min.css";
@import "assets/css/doctorstyle.css";

html, body { height: 100%; }
body { margin: 0; }

img {
  max-width: 100%;
  /*height: auto;*/
}

.pointer {
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #b3b3b3;
}

.half-divider {
  width: 50%;
  height: 1px;
  background-color: rgba(179, 179, 179, 0.5);
}

.text-hover-primary:hover {
  color: #09e5ab !important;
}

.online-border {
  border: 2px solid #00e65b;
}

.form-errors {
  font-size: 12px;
  color: red;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.mat-dialog-container {
  padding: 16px 8px !important;
}

.mat-dialog-content {
  margin: 0 !important;
  padding: 0 24px !important;
}

.mat-dialog-content::-webkit-scrollbar {
  width:1px !important;
}

.mat-dialog-content::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.mat-dialog-content::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.mat-dialog-content::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.mat-drawer-content::-webkit-scrollbar {
  width: 1px !important;
}

.mat-drawer-content::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.mat-drawer-content::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.mat-drawer-content::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.uploader-text {
  max-width: 40%;
}

.width-control {
  max-width: 45%;
}

/* =============================================== Media queries ======================================= */

/*@media (min-width: 300px) and (max-width: 399.98px) {*/
/*  .mat-dialog-container {*/
/*    min-width: 250px !important;*/
/*    max-width: 90% !important;*/
/*    margin: auto !important;*/
/*  }*/
/*}*/

/*@media (min-width: 400px) and (max-width: 499.98px) {*/
/*  .mat-dialog-container {*/
/*    min-width: 350px !important;*/
/*    max-width: 90% !important;*/
/*    margin: auto !important;*/
/*  }*/
/*}*/

/*@media (min-width: 500px) and (max-width: 599.98px) {*/
/*  .mat-dialog-container {*/
/*    min-width: 450px !important;*/
/*    max-width: 90% !important;*/
/*    margin: auto !important;*/
/*  }*/
/*}*/

@media (min-width: 600px) and (max-width: 767.98px) {
  .mat-dialog-container {
    min-width: 550px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (max-width: 768px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
    width: auto !important;
  }

  .mat-table[matTableResponsive] .mat-header-row {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    position: sticky;
    top: 0;
    z-index: 2;
    min-height: 60px;
  }
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 1rem 0.5rem;
    justify-content: space-around;
  }
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell > .mat-sort-header-container {
    padding-left: 15px;
  }
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell:not([mat-sort-header]),
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell.mat-sort-header-disabled,
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell.mat-column-actions {
    display: none;
  }
  .mat-table[matTableResponsive] .mat-row {
    height: auto;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-cell:not(.mat-column-actions) {
    padding: 0.5rem;
    word-break: break-word;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-cell:not(.mat-column-actions) > * {
    margin: 0 6px;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-cell:not(.mat-column-actions):before {
    content: attr(data-column-name);
    text-align: left;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-column-actions {
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-column-actions > button {
    margin: 0.5rem 0;
  }
  .mat-table[matTableResponsive] .mat-row:last-of-type td:last-of-type {
    border: none;
  }
  .mat-table[matTableResponsive] .mat-row:not(:last-of-type) td:last-of-type {
    border-bottom: 0.5rem solid #fafafa;
  }
  .mat-card .mat-table[matTableResponsive], .mat-expansion-panel .mat-table[matTableResponsive] {
    border: 0.5rem solid #fafafa;
  }

}

@media (min-width: 768px) {
  .main-logo {
    width: 70%;
  }

  .uploader-text {
    max-width: 60%;
  }

  .width-control {
    max-width: 70%;
  }
}

@media (min-width: 300px) and (max-width: 399.98px) {
  .mat-dialog-container {
    min-width: 250px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (min-width: 400px) and (max-width: 499.98px) {
  .mat-dialog-container {
    min-width: 350px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (min-width: 500px) and (max-width: 599.98px) {
  .mat-dialog-container {
    min-width: 450px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (min-width: 600px) and (max-width: 767.98px) {
  .mat-dialog-container {
    min-width: 550px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (max-width: 767.98px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .mat-dialog-container {
    min-width: 500px;
  }
}

@media (max-width: 992px) {
  .full-width-dialog {
    max-width: 100vw !important;
    width: 96% !important;
  }
}
